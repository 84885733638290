/* # FUNCTIONS
========================================================================== */


$(function () {

    "use strict";
    var $window = $(window);
    var $document = $(document);
    var winWidthSm = 991;
    var $body = $('body');

    $(document).ready(function () {

        scroll();
        sidebarMenu();
        filtersMenu();
        navigation_menu();

    });


    // ---------------------------------------------------------------------------------------------------------------------------->
    // SCROLL FUNCTIONS   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->

    function scroll() {

        // //Click Event to Scroll to Top
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $('.scroll-top').fadeIn();
            } else {
                $('.scroll-top').fadeOut();
            }
        });
        $('.scroll-top').click(function () {
            $('html, body').animate({ scrollTop: 0 }, 800);
            return false;
        });

        // Scroll Down Elements
        $('.scroll-down[href^="#"], .scroll-to-target[href^="#"]').on('click', function (e) {
            e.preventDefault();

            var target = this.hash;
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        });

    };


    // ---------------------------------------------------------------------------------------------------------------------------->
    // NAVIGATION MENU FUNCTIONS   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->
    
    function navigation_menu() {

        var navMenuLink = $(".nav-menu > ul > li"),
            //menuIconBtn = $('.menu-dropdown-icon'),
            dropDown_Menu = $('.nav-dropdown'),
            nav_menu_item = $('.nav-menu-item'),
            nav_Mobile_Btn = $(".menu-mobile-btn"),
            nav_menu_wrap = $(".nav-menu");

        // Dropdown Menu Icon
        $('.nav-menu > ul > li:has( > .nav-dropdown)').prepend('<span class="menu-dropdown-icon"></span>');
        $('.nav-dropdown > ul > li:has( > .nav-dropdown-sub)').addClass('sub-dropdown-icon');
        $(".nav-menu > ul > li:has( > .nav-dropdown)").addClass('dd-menu-dropdown-icon');
        $window.on('resize', function () {
            if ($(window).width() > winWidthSm) {
                $('.nav-dropdown > ul > li ul li:has(.nav-dropdown-sub)').addClass('sub-dropdown-icon');
            };
            if ($(window).width() <= winWidthSm) {
                $('.nav-dropdown > ul > li ul li:has(.nav-dropdown-sub)').removeClass('sub-dropdown-icon');
            };
        });

        // Dropdown Menu
        navMenuLink.on('mouseenter mouseleave', function (e) {
            if ($(window).width() > winWidthSm) {
                $(this).children(".nav-dropdown").stop(true, false).fadeToggle(150);
                e.preventDefault();
            }
        });
        $('.menu-dropdown-icon').on('click', function () {
            if ($(window).width() <= winWidthSm) {
                $(this).siblings(".nav-dropdown").stop(true, false).fadeToggle(150);
            }
        });
        $window.on('resize', function () {
            if ($(window).width() > winWidthSm) {
                $(".nav-dropdown, .nav-dropdown-sub").fadeOut(150);
            }
        });

        // Sub Dropdown Menu
        nav_menu_item.on('mouseenter mouseleave', function (e) {
            if ($(window).width() > winWidthSm) {
                $(this).children(".nav-dropdown-sub").stop(true, false).fadeToggle(150);
                e.preventDefault();
            }
        });
        nav_menu_item.on('click', function () {
            if ($(window).width() <= winWidthSm) {
                $(this).children(".nav-dropdown-sub").stop(true, false).fadeToggle(150);
            }
        });

        // Dropdon Center align
        $window.on('resize', function () {
            dropDown_Menu.each(function (indx) {
                if ($(this).hasClass("center")) {
                    var dropdownWidth = $(this).outerWidth();
                    var navItemWidth = $(this).parents(nav_menu_item).outerWidth();
                    var dropdown_halfWidth = parseInt(dropdownWidth / 2);
                    var navItem_halfWidth = parseInt(navItemWidth / 2);
                    var totlePosition = parseInt(dropdown_halfWidth - navItem_halfWidth);
                    if ($window.width() > winWidthSm) {
                        $(this).css("left", "-" + totlePosition + "px");
                    } else {
                        $(this).css("left", "0");
                    };
                }
            });
        });

        // Mobile Menu Button
        nav_Mobile_Btn.on('click', function (e) {
            nav_menu_wrap.toggleClass('show-on-mobile');
            $(this).toggleClass("active");
            e.preventDefault();
        });

    };


    //---------------------------------------------------------------------------------------------------------------------------->
    // SIDEBAR MENU  
    //---------------------------------------------------------------------------------------------------------------------------->
    
    function sidebarMenu() {

        var sidebarMenuBtn = $('.sidebar-menu_btn'),
            sidebarMenu = $('.sidebar-menu'),
            sidebarMenuClose = $('.sidebar-close-icon'),
            sidebarBtnActive = 'active',
            sidebarMenuActive = 'sidebar-menu-open',
            sidebarBtnParentActive = 'cart-active';

        var openSidebar = function () {
            sidebarMenuBtn.toggleClass(sidebarBtnActive);
            sidebarMenu.toggleClass(sidebarMenuActive);
            sidebarMenuBtn.parent().toggleClass(sidebarBtnParentActive);
        }
        var closeSidebar = function () {
            sidebarMenuBtn.removeClass(sidebarBtnActive);
            sidebarMenu.removeClass(sidebarMenuActive);
            sidebarMenuBtn.parent().removeClass(sidebarBtnParentActive);
        }

        sidebarMenuClose.on('click', function (event) {
            closeSidebar();
        });

        $('.search-menu_btn, .cart-menu_btn').on('click', function (e) {
            closeSidebar();
        });

        sidebarMenuBtn.on('click', function (event) {
            event.stopPropagation();
            openSidebar();
        });

        $document.on('click touchstart', function (event) {
            if (!$(event.target).closest('.sidebar-menu, .sidebar-menu_btn').length) {
                closeSidebar();
            }
        });

    };

    //---------------------------------------------------------------------------------------------------------------------------->
    // FILTERS MENU  
    //---------------------------------------------------------------------------------------------------------------------------->
   
    function filtersMenu() {

        var filtersMenuBtn = $('.filters-menu_btn'),
            filtersMenu = $('.filters-menu'),
            filtersMenuClose = $('.filters-close-icon'),
            filtersBtnActive = 'active',
            filtersMenuActive = 'filters-menu-open',
            filtersBtnParentActive = 'cart-active';

        var openfilters = function () {
            filtersMenuBtn.toggleClass(filtersBtnActive);
            filtersMenu.toggleClass(filtersMenuActive);
            filtersMenuBtn.parent().toggleClass(filtersBtnParentActive);
        }
        var closefilters = function () {
            filtersMenuBtn.removeClass(filtersBtnActive);
            filtersMenu.removeClass(filtersMenuActive);
            filtersMenuBtn.parent().removeClass(filtersBtnParentActive);
        }

        filtersMenuClose.on('click', function (event) {
            closefilters();
        });

        $('.search-menu_btn, .cart-menu_btn').on('click', function (e) {
            closefilters();
        });

        filtersMenuBtn.on('click', function (event) {
            event.stopPropagation();
            openfilters();
        });

        $document.on('click touchstart', function (event) {
            if (!$(event.target).closest('.filters-menu, .filters-menu_btn').length) {
                closefilters();
            }
        });

    };
   
    // ------------------------------------------------------- //
    //   Home Slider
    // ------------------------------------------------------ //
    var homeSlider = $('.home-slider');
    homeSlider.owlCarousel({
        loop: true,
        autoplay: true,
        autoplayTimeout: 9999,
        autoplayHoverPause: false,
        items: 1,
        margin: 0,
        nav: false,
        dots: false,
        smartSpeed: 2000,
        
        onRefresh: function () {
            homeSlider.find('.item').height('');
        },
        onRefreshed: function () {
            var maxHeight = 0;
            var items = homeSlider.find('.item');
            items.each(function () {
                var itemHeight = $(this).height();
                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }
            });
            items.height(maxHeight);
        }
    });

    // ------------------------------------------------------- //
    //   Item Slider
    // ------------------------------------------------------ //

    $('.featured-items-slider').owlCarousel({
        loop: true,
        autoplay: true,
        autoplayTimeout: 9999,
        autoplayHoverPause: false,
        items: 1,
        margin: 0,
        nav: false,
        dots: true,
        smartSpeed: 2000,
        loop:true,
        margin:10,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
            },
            576:{
                items:2,
            },
            768:{
                items:3,
            },
            992:{
                items:4,
            }
        }
    });

    // ------------------------------------------------------- //
    // Quantity
    // ------------------------------------------------------ //

    $(function () {
        var qty_min = $('.quantity').attr("min");
        var qty_max = $('.quantity').attr("max");

        $(".quantityPlus").on('click', function () {
            var currentVal = parseInt($(this).next(".quantity").val(), 10);
            var str = $("p:first").text();
            if (currentVal != qty_max) {
                $(this).next(".quantity").val(currentVal + 1);
            }
        });

        $(".quantityMinus").on('click', function () {
            var currentVal = parseInt($(this).prev(".quantity").val(), 10);
            if (currentVal != qty_min) {
                $(this).prev(".quantity").val(currentVal - 1);
            }
        });
    });

    // ------------------------------------------------------- //
    //   Tooltip
    // ------------------------------------------------------ //

    $('[data-toggle="tooltip"]').tooltip();

    // ---------------------------------------------------------------------------------------------------------------------------->
    // TOASTR   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->
    function LaunchErrorAlert(title, description) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 0,
            "extendedTimeOut": 0,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["error"](description, title)
    }

    function LaunchWarningAlert(title, description) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 0,
            "extendedTimeOut": 0,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["warning"](description, title)
    }

    function LaunchInfoAlert(title, description) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 6000,
            "extendedTimeOut": 1000,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["info"](description, title)
    }

    function LaunchSuccessAlert(title, description) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 6000,
            "extendedTimeOut": 1000,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["success"](description, title)
    }

    // LANZAMOS ERRORES CON TOASTR
    $(".toastr-alert-error").each(function () {
        LaunchErrorAlert("", $(this).html());
    });
    $(".toastr-alert-warning").each(function () {
        LaunchWarningAlert("", $(this).html());
    });
    $(".toastr-alert-info").each(function () {
        LaunchInfoAlert("", $(this).html());
    });
    $(".toastr-alert-success").each(function () {
        LaunchSuccessAlert("", $(this).html());
    });


    // ---------------------------------------------------------------------------------------------------------------------------->
    // CUBE Portfolio   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->
    (function($, window, document, undefined) {
        'use strict';
    
        // init cubeportfolio
        $('#js-grid-masonry').cubeportfolio({
            filters: '#js-filters-masonry',
            //loadMore: '#js-loadMore-masonry',
            //loadMoreAction: 'click',
            layoutMode: 'grid',
            defaultFilter: '*',
            animationType: 'slideDelay',
            gapHorizontal: 20,
            gapVertical: 20,
            gridAdjustment: 'responsive',
            mediaQueries: [{
                width: 1200,
                cols: 6
            }, {
                width: 992,
                cols: 5
            }, {
                width: 768,
                cols: 4
            }, {
                width: 576,
                cols: 3
            }, {
                width: 0,
                cols: 2
            }],
            caption: 'overlayBottomAlong',
            displayType: 'bottomToTop',
            displayTypeSpeed: 100,
    
            // lightbox
            lightboxDelegate: '.cbp-lightbox',
            lightboxGallery: true,
            lightboxTitleSrc: 'data-title',
            lightboxCounter: '<div class="cbp-popup-lightbox-counter">{{current}} de {{total}}</div>',
        });
    })(jQuery, window, document);


});
