
//TOASTR
import toastr from 'toastr';
window.toastr = toastr;
import "./plugins/toastr/toastr.scss";

///FONTAWESOME 5
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';

//FANCYBOX
import "../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.js";
import "./plugins/fancybox/core.scss";
import "./plugins/fancybox/fullscreen.scss";
import "./plugins/fancybox/share.scss";
import "./plugins/fancybox/slideshow.scss";
import "./plugins/fancybox/thumbs.scss";

///FONTAWESOME 5
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';

//OWLCAROUSEL
import 'owl.carousel'
import "./plugins/owlcarousel/src/scss/owl.carousel.scss";
import "./plugins/owlcarousel/src/scss/_theme.default.scss";

//CUBE Portfolio
import "../node_modules/cubeportfolio/src/css/cubeportfolio.min.css";
import "../node_modules/cubeportfolio/src/js/jquery.cubeportfolio.min.js";

